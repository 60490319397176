import type {
  OrderTemplate,
  OrderTemplateLine,
  Product,
  SaveOrderTemplateInput,
  SaveOrderTemplateResult,
  BasketLineInput,
} from './types';

export const ORDER_TEMPLATES_REQUESTED = 'ORDER_TEMPLATES_REQUESTED' as const;
export const loadOrderTemplates = () => ({ type: ORDER_TEMPLATES_REQUESTED });

export const ORDER_TEMPLATES_RECEIVED = 'ORDER_TEMPLATES_RECEIVED' as const;
export const orderTemplatesReceived = ({ orderTemplates }: { orderTemplates: OrderTemplate[] | null }) => ({
  type: ORDER_TEMPLATES_RECEIVED,
  payload: orderTemplates,
});
export type OrderTemplatesReceivedAction = ReturnType<typeof orderTemplatesReceived>;

export const ORDER_TEMPLATE_LINES_REQUESTED = 'ORDER_TEMPLATE_LINES_REQUESTED' as const;
export const loadOrderTemplateLines = (id: string) => ({
  type: ORDER_TEMPLATE_LINES_REQUESTED,
  payload: { id },
});

export const ORDER_TEMPLATE_LINES_RECEIVED = 'ORDER_TEMPLATE_LINES_RECEIVED' as const;
export const orderTemplateLinesReceived = (id: string, orderTemplateLines: OrderTemplateLine[] | null = null) => ({
  type: ORDER_TEMPLATE_LINES_RECEIVED,
  payload: { id, orderTemplateLines },
});
export type OrderTemplatesLinesReceivedAction = ReturnType<typeof orderTemplateLinesReceived>;

export const ORDER_TEMPLATES_ADDING_REQUESTED = 'ORDER_TEMPLATES_ADDING_REQUESTED' as const;
export const addToBasket = (ids: string[]) => ({
  type: ORDER_TEMPLATES_ADDING_REQUESTED,
  payload: { ids },
});

export const ORDER_TEMPLATES_ADDED = 'ORDER_TEMPLATES_ADDED' as const;
export const orderTemplatesAdded = (countAdded: number) => ({
  type: ORDER_TEMPLATES_ADDED,
  payload: countAdded,
});
export type OrderTemplatesAddedAction = ReturnType<typeof orderTemplatesAdded>;

export const ORDER_TEMPLATES_REMOVAL_REQUESTED = 'ORDER_TEMPLATES_REMOVAL_REQUESTED' as const;
export const removeTemplates = (ids: string[]) => ({
  type: ORDER_TEMPLATES_REMOVAL_REQUESTED,
  payload: { ids },
});

export const ORDER_TEMPLATES_REMOVED = 'ORDER_TEMPLATES_REMOVED' as const;
export const orderTemplatesRemoved = (ids: string[]) => ({
  type: ORDER_TEMPLATES_REMOVED,
  payload: ids,
});
export type OrderTemplatesRemovedAction = ReturnType<typeof orderTemplatesRemoved>;

//3.1. Editable order templates - Start
export const ORDER_TEMPLATE_PRODUCTS_REQUESTED = 'ORDER_TEMPLATE_PRODUCTS_REQUESTED' as const;
export const requestProducts = (ids: string[], loadAllProducts: boolean ) => ({
    type: ORDER_TEMPLATE_PRODUCTS_REQUESTED,
    payload: { ids, loadAllProducts },
});

export const ORDER_TEMPLATE_PRODUCTS_RECEIVED = 'ORDER_TEMPLATE_PRODUCTS_RECEIVED' as const;
export const receiveProducts = (products: Product[]) => ({
    type: ORDER_TEMPLATE_PRODUCTS_RECEIVED,
    payload: { products },
});

export type OrderTemplateReceiveProductsAction = ReturnType<typeof receiveProducts>;

export const ORDER_TEMPLATE_CREATED = 'ORDER_TEMPLATE_CREATED' as const;
export const saveOrderTemplate = (input: SaveOrderTemplateInput) => ({
    type: ORDER_TEMPLATE_CREATED,
    payload: { input },
});
export type SaveOrderTemplateAction = ReturnType<typeof saveOrderTemplate>;

export const ORDER_TEMPLATE_CREATE_FAILED = 'ORDER_TEMPLATE_CREATE_FAILED' as const;
export const orderTemplateReceivedErrorMessage = (result: SaveOrderTemplateResult) => ({
    type: ORDER_TEMPLATE_CREATE_FAILED,
    payload: result,
});
export type OrderTemplateReceivedErrorMessageAction = ReturnType<typeof orderTemplateReceivedErrorMessage>;

export const ORDER_TEMPLATE_BASKET_LINE_LIST_ADDING_REQUESTED = 'ORDER_TEMPLATE_BASKET_LINE_LIST_ADDING_REQUESTED' as const;
export const addLinesToBasket = (input: SaveOrderTemplateInput) => ({
    type: ORDER_TEMPLATE_BASKET_LINE_LIST_ADDING_REQUESTED,
    payload: { input },
});
export type AddLinesToBasketTemplateAction = ReturnType<typeof addLinesToBasket>;

export const ORDER_TEMPLATE_DELETE_BASKET_LINE = 'ORDER_TEMPLATE_DELETE_BASKET_LINE' as const;
export const deleteBasketLine = (line: BasketLineInput, orderTemplateId: string) => ({
    type: ORDER_TEMPLATE_DELETE_BASKET_LINE,
    payload: { line, orderTemplateId },
});
export type DeleteBasketLine = ReturnType<typeof deleteBasketLine>;

export const ORDER_TEMPLATE_BASKET_CLEAR_ALL_LINES = 'ORDER_TEMPLATE_BASKET_CLEAR_ALL_LINES' as const;
export const clearAllBasketLines = (id: string) => ({
    type: ORDER_TEMPLATE_BASKET_CLEAR_ALL_LINES,
    payload: { id },
});
export type ClearAllBasketLines = ReturnType<typeof clearAllBasketLines>;
//3.1.Editable order templates - End

export type OrderTemplateAction = ReturnType<
  | typeof loadOrderTemplates
  | typeof orderTemplatesReceived
  | typeof loadOrderTemplateLines
  | typeof orderTemplateLinesReceived
  | typeof addToBasket
  | typeof orderTemplatesAdded
  | typeof removeTemplates
  | typeof orderTemplatesRemoved
  | typeof requestProducts
  | typeof receiveProducts
  | typeof saveOrderTemplate
  | typeof orderTemplateReceivedErrorMessage
  | typeof addLinesToBasket
>;
