import type { RoutingAction } from 'behavior/routing/actions';
import type { OrderTemplate, Product } from './types';
import {
  OrderTemplatesAddedAction,
  OrderTemplatesRemovedAction,
  OrderTemplatesReceivedAction,
  OrderTemplatesLinesReceivedAction,
  OrderTemplateAction,
  OrderTemplateReceiveProductsAction,
  ORDER_TEMPLATES_ADDED,
  ORDER_TEMPLATES_REMOVED,
  ORDER_TEMPLATES_RECEIVED,
  ORDER_TEMPLATE_LINES_RECEIVED,
  ORDER_TEMPLATE_PRODUCTS_RECEIVED,
  ORDER_TEMPLATE_CREATE_FAILED,
  OrderTemplateReceivedErrorMessageAction,
} from 'behavior/pages/orderTemplates/actions';
import { createReducer } from 'utils/redux';
import { UpdateType } from './constants';
import { PageComponentNames } from '../componentNames';
import { arrayToObject } from 'utils/helpers';

type StatusAdded = {
  type: UpdateType.Added;
  countAdded: number;
};

type StatusRemoved = {
  type: UpdateType.Removed;
};

type State = {
  component: PageComponentNames.OrderTemplates;
  status?: StatusAdded | StatusRemoved;
  items?: OrderTemplate[] | null;
  products: Record<string, Product> | null; //3.1. Editable order templates
  isFailed?: false; //3.1. Editable order templates
};

export default createReducer<State, OrderTemplateAction | RoutingAction>(null as unknown as State, {
  [ORDER_TEMPLATES_ADDED]: onOrderTemplatesAdded,
  [ORDER_TEMPLATES_REMOVED]: onOrderTemplatesRemoved,
  [ORDER_TEMPLATES_RECEIVED]: onOrderTemplatesReceived,
  [ORDER_TEMPLATE_LINES_RECEIVED]: onOrderTemplateLinesReceived,
  [ORDER_TEMPLATE_PRODUCTS_RECEIVED]: onProductsReceived, //3.1. Editable order templates
  [ORDER_TEMPLATE_CREATE_FAILED]: onOrderTemplateReceivedErrorMessage, //3.1. Editable order templates
});

function onOrderTemplatesAdded(state: State, action: OrderTemplatesAddedAction) {
  return {
    ...state,
    status: {
      type: UpdateType.Added as const,
      countAdded: action.payload,
    },
  };
}

function onOrderTemplatesRemoved(state: State, action: OrderTemplatesRemovedAction) {
  const status = { type: UpdateType.Removed as const };
  if (!state.items)
    return { ...state, status };

  return {
    ...state,
    items: state.items.filter(t => !action.payload.includes(t.id)),
    status,
  };
}

function onOrderTemplatesReceived(state: State, action: OrderTemplatesReceivedAction) {
  return {
    ...state,
    items: action.payload,
  };
}

function onOrderTemplateLinesReceived(state: State, action: OrderTemplatesLinesReceivedAction) {
  if (!state.items)
    return state;

  const { id: templateId, orderTemplateLines } = action.payload;

  return {
    ...state,
    items: state.items.map(template => {
      if (template.id !== templateId)
        return template;

      return { ...template, lines: orderTemplateLines };
    }),
  };
}

//3.1. Editable order templates
function onOrderTemplateReceivedErrorMessage(state: State, action: OrderTemplateReceivedErrorMessageAction) {
    return {
        ...state,
        error: {
            ...action.payload,
        },
    };
}

//3.1. Editable order templates
function onProductsReceived(state: State, action: OrderTemplateReceiveProductsAction) {
    const productList = action.payload.products == null ? [] : action.payload.products;
    return {
        ...state,
        products: { ...state.products, ...arrayToObject(productList, (product: Product) => product.id) },
    };
}