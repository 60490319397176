//3.1. Editable order templates
import type { Handler } from '../types';
import type { OrderTemplate } from './types';
import { editOrderTemplatesPageQuery } from './queries';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { PageComponentNames } from '../componentNames';
import { initSystemPageContent, SystemPage, SystemPageData } from '../system';
import { orderTemplatesReceived } from './actions';
import { loadSystemPageQuery } from '../system/queries';
import { generateKey } from 'utils/helpers';
import { RouteName } from 'routes';

const editOrderTemplateHandler: Handler<EditOrderTemplateRouteData, EditOrderTemplatePage> = ({ params }, _state$, { api }) => {
    const id = params?.id;

    if (params?.previewToken) {
        return api.graphApi<SystemPageResponse>(loadSystemPageQuery('editOrderTemplate')).pipe(
            map(({ pages: { editOrderTemplate: page } }) => {
                if (!page)
                    return null;

                const orderTemplates = Array.from(Array(5)).map(() => ({ id: generateKey(), name: null, description: '', lines: [], createdDate: '', modifiedDate: '' }));

                return {
                    page: { ...page, component: PageComponentNames.EditOrderTemplate as const },
                    action$: of(orderTemplatesReceived({ orderTemplates })),
                };
            }),
            initSystemPageContent(),
        );
    }

    return api.graphApi<EditOrderTemplatePageResponse>(editOrderTemplatesPageQuery, { id }).pipe(
        map(({ pages: { editOrderTemplate: page }, orderTemplate }) => {
            if (!page)
                return null;

            return {
                page: {
                    ...page,
                    orderTemplate,
                    component: PageComponentNames.EditOrderTemplate as const,
                },
            };
        }),
        initSystemPageContent(),
    );
};

export default editOrderTemplateHandler;

type EditOrderTemplatePage = SystemPage & {
    component: PageComponentNames.EditOrderTemplate;
};

type EditOrderTemplateRouteData = {
    routeName: RouteName.EditOrderTemplate;
    params?: { previewToken?: string; id: string };
};

type SystemPageResponse = {
    pages: {
        editOrderTemplate: SystemPageData;
    };
    orderTemplate: OrderTemplate;
};

type EditOrderTemplatePageResponse = SystemPageResponse;
