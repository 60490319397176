import type { Handler } from '../types';
import type { Api } from 'utils/api';
import { resetPasswordPageQuery, userDataQuery } from './queries';
import { mergeMap, switchMap, pluck, first, map } from 'rxjs/operators';
import { RouteName, routesBuilder } from 'routes';
import { navigateTo } from 'behavior/events';
import { of } from 'rxjs';
import { PageComponentNames } from '../componentNames';
import { StoreType, areSettingsLoaded } from 'behavior/settings';
import { ContentSystemPage, ContentSystemPageData, initContentSystemPage, initContentSystemPageContent, loadContentSystemPageQuery } from '../system';

const handler: Handler<ResetPasswordRouteData, ResetPasswordPage> = (routeData, state$, { api }) => {
  return state$.pipe(
    pluck('settings'),
    first(areSettingsLoaded),
    switchMap(
      settings => settings.storeType === StoreType.Closed
        ? loadClosedStoreResetPasswordPage(routeData, api)
        : loadSystemResetPasswordPage(routeData, api),
    ),
  );
};

export default handler;

function loadClosedStoreResetPasswordPage(routeData: ResetPasswordRouteData, api: Api) {
  if (routeData.params.previewToken)
    return loadClosedStoreResetPasswordPreviewPage();

  return api.graphApi<ClosedStoreResetPasswordResponse>(userDataQuery, { token: routeData.params.token }).pipe(
    mergeMap(({ profile }) => {
      if (!profile.userData)
        return of({ action$: of(navigateTo(routesBuilder.forForgotPassword(), undefined, { replaceHistory: true })) });

      return of({
        page: {
          emptyLayout: true,
          component: PageComponentNames.ClosedStoreResetPassword as const,
          userData: profile.userData,
          token: routeData.params.token,
        },
      });
    }),
  );
}

function loadSystemResetPasswordPage(routeData: ResetPasswordRouteData, api: Api) {
  if (routeData.params.previewToken)
    return loadResetPasswordPreviewPage(api);

  return api.graphApi<PublicStoreResetPasswordResponse>(resetPasswordPageQuery, { token: routeData.params.token }).pipe(
    mergeMap(({ profile: { userData }, pages }) => {
      if (!userData)
        return of({ action$: of(navigateTo(routesBuilder.forForgotPassword(), undefined, { replaceHistory: true })) });

      const page = pages.resetPassword;
      if (!page)
        return of(null);

      const result = {
        ...page,
        component: PageComponentNames.ResetPassword as const,
        userData,
        token: routeData.params.token,
      };

      return of({ page: initContentSystemPage(result) });
    }),
  );
}

function loadClosedStoreResetPasswordPreviewPage() {
  return of({
    page: {
      emptyLayout: true,
      component: PageComponentNames.ClosedStoreResetPassword as const,
      token: '',
    },
  });
}

function loadResetPasswordPreviewPage(api: Api) {
  return api.graphApi<PublicStoreResetPasswordPreviewResponse>(loadContentSystemPageQuery('resetPassword')).pipe(
    map(({ pages: { resetPassword: page } }) => !page ? null : ({
      page: {
        ...page,
        component: PageComponentNames.ResetPassword as const,
        token: '',
      },
    })),
    initContentSystemPageContent(),
  );
}

type UserData = {
  name: string;
  email: string;
};

type ResetPasswordRouteData = {
  routeName: RouteName.ResetPassword;
  params: {
    token: string;
    previewToken?: string;
    language?: number;
  };
};

type PublicStoreResetPasswordPage = ContentSystemPage & {
  component: PageComponentNames.ResetPassword;
  userData?: UserData;
  token: string;
};

type ClosedStoreResetPasswordPage = {
  component: PageComponentNames.ClosedStoreResetPassword;
  emptyLayout: boolean;
  userData?: UserData;
  token: string;
};

type ResetPasswordPage = ClosedStoreResetPasswordPage | PublicStoreResetPasswordPage;

type ClosedStoreResetPasswordResponse = {
  profile: {
    userData: UserData | null;
  };
};

type PublicStoreResetPasswordResponse = {
  profile: {
    userData: UserData | null;
  };
  pages: {
    resetPassword: ContentSystemPageData;
  };
};

type PublicStoreResetPasswordPreviewResponse = {
  pages: {
    resetPassword: ContentSystemPageData;
  };
};
