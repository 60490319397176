export const enum UpdateType {
  Added = 'Added',
  Removed = 'Removed',
}

//3.1. Editable order templates
export const enum OrderTemplateType {
  CreateTemplate = 'CreateTemplate',
  EditTemplate = 'EditTemplate',
  AddToBasketTemplate = 'AddToBasketTemplate',
}